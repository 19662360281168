import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'
import { Col, Row, Button } from "reactstrap";
import { Link } from 'react-scroll';
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { setModal } from '../../reducers/Home/actions'
import { getProducts, getProductsDeclaration } from '../../reducers/Products/actions'
import Layout from "../../components/Layout";
import RecentNews from "../../components/RecentNews/RecentNews";
import Card from "../../components/Card/Card";
import BuildingGuideModal from "../../components/Modals/BuildingGuideModal/BuildingGuideModal";
import ConfirmationModal from '../../components/Modals/ConfirmationModal/ConfirmationModal'
import SideNav from '../../components/SideNav/SideNav';
import CheckIcon from '@mui/icons-material/Check';
import { urlDescription } from '../../utils/pageDescription'
import ecoDeclaration from '../../assets/images/eco-declaration.png';
import wallsImg from "../../assets/images/walls.png";
import wallsDescription2 from "../../assets/images/walls-description-image.svg";
import houseImg from "../../assets/images/walls-house.png";
import wallsYTONG from "../../assets/images/walls-ytong.jpg";
import ytongLogo from '../../assets/images/ytong-logo.svg'
import "./styles.scss";

const WallsYTONG = ({ data, products, getProducts, getProductsDeclaration, successModal, setModal }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [id, setId] = useState(null)

  useEffect(() => {
    getProducts({ type: 'ytong' })
  }, [getProducts])

  const staticWallsData = data && data.wallsYtong.edges;

  const staticLastProject = data && data.lastProjects.edges;
  const staticLastNew = data && data.lastNews.edges;
  const staticLastArticle = data && data.lastArticles.edges;

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <>
      <Layout description={urlDescription[url.pathname]}>
        <div className="walls-decision-wrapper">
          <div className="walls-decision-container">
            <Row name='top' className="walls-decision-top-row mb-5">
              <Col className='sidenav-menu-col' xs={12} lg={4}>
                <SideNav
                  navTitle="Решения за стени"
                  products={staticWallsData}
                  wallSolutions
                />
              </Col>
              <Col xs={12} lg={8}>
                <img alt="wall" className="w-100" src={wallsImg} />
              </Col>
            </Row>
            <hr />

            <Row>
              <Col xs={12}>
                <h2 className="h4 mb-4 font-weight-bold">
                  Решенията, които променят дома
                </h2>
              </Col>
              <Col className="align-self-center" lg={5}>
                <div className="round">
                  <img alt="wall" className="w-100" src={houseImg} />
                </div>
              </Col>
              <Col className="align-self-center" lg={7}>
                <p>
                  Когато избирате материали за своята къща, Вие избирате начина
                  си на живот за дълги години напред. Вашите решения ще останат
                  вградени в стените на дома Ви. Често забравяме колко много и
                  колко отговорни са техните функции:
                </p>
                <ul>
                  <li>
                    носеща – изпълняват я и носещите и неносещите стени
                    ("Неносещите" стени носят теглото си, окачени мебели, уреди,
                    санитарно оборудване, вятър, натоварване от земетръс.);
                  </li>
                  <li>пожарозащитна;</li>
                  <li>поддържане на подходящ микроклимат в помещенията;</li>
                  <li>ограждаща и разпределителна;</li>
                  <li>шумоизолационна;</li>
                  <li>топлоизолационна и топлоакумулираща;</li>
                </ul>
              </Col>
            </Row>
            <Row className="walls-decision-top-row">
              <Col>
                <p>
                  Клетъчните бетони и калциево-силикатните изделия представляват
                  качествен скок в еволюцията на зидарските материали.
                  Съчетаването в един единствен продукт на няколко полезни за
                  строителството свойства дава нова перспектива за развитие на
                  класическата технология за изграждане на стени.
                  Усъвършенстваните материали подобряват качеството на живот във
                  Вашия дом, носят добавена стойност и съхраняват инвестицията
                  Ви.
                </p>
                <p>
                  Блоковете YTONG са с равномерна и еднородна фина пореста
                  структура, която им дава голямо предимство – еднакви
                  физико-механични характеристики във всички направления.
                  Зидарията се изпълнява лесно, бързо и с минимално количество
                  отпадъци. Блоковете се обработват безпроблемно, както машинно,
                  така и с леки ръчни инструменти.
                </p>
                <div className="my-5 walls-decision-img">
                  <img alt="wall" className="w-100 br-8" src={wallsYTONG} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4} className='pb-5'>
                <h5 className="mb-3 font-weight-bold">
                  YTONG THERMO (B2, D350)
                </h5>
                <p>
                  Блоковете <b>YTONG THERMO (B2, D350)</b> се характеризират
                  с изключителни топлоизолационни и пожарозащитни свойства.
                  Те са сред най-леките продукти за зидария, предлагани на
                  нашия пазар. По-малката маса на стените повишава
                  сигурността и устойчивостта на сградите при земетресение.
                </p>
              </Col>
              <Col xs={12} lg={4} className='pb-5'>
                <h5 className="mb-3 font-weight-bold">
                  YTONG KOMFORT (B2.5, D400)
                </h5>
                <p>
                  Блоковете <b>YTONG KOMFORT (B2.5, D400)</b> са с
                  универсално приложение. В жилищните сгради обикновено се
                  използват за комплексни стени. Топлоизолационната зидария
                  от YTONG KOMFORT се съчетава с плочи Multipor за постигане
                  на топлосъхранение и висока степен на енергийна
                  ефективност.
                </p>
              </Col>
              <Col xs={12} lg={4} className='pb-5'>
                <h5 className="mb-3 font-weight-bold">
                  YTONG AKUSTIK (B5, D600)
                </h5>
                <p>
                  Блоковете <b>YTONG AKUSTIK (B5, D600)</b> се отличават с
                  увеличена якост, която позволява да се използват за
                  изпълнение на носещи стенни конструкции. Водещото им
                  качество е защитата от шум. То определя и основното им
                  приложение – за вътрешни преградни стени. Сравнително
                  малки дебелина на зидариите удовлетворяват високите
                  нормативни изисквания за шумоизолация в жилищните сгради.
                  Блоковете притежават отлични пожарозащитни свойства.
                </p>
              </Col>
            </Row>
          </div>
          <Row className="walls-decision-description-wrapper py-5">
            <div className="py-0 walls-decision-container">
              <p>
                Блоковете YTONG, независимо от какъв вид са, се свързват помежду
                си с тънкослоен лепилен зидарски разтвор, който трябва да се
                нанася равномерно по цялата площ на техните контактни
                повърхности. Дебелината на зидарската фуга трябва да бъде между
                1 и 3 mm за постигане на максимално добри топлоизолация и
                пожарозащита.
              </p>
              Блоковете се предлагат в две разновидности:
              <ul className='m-0'>
                <li>с гладки челни повърхности.</li>
                <li>
                  с профилирани на длъб и зъб (на нут и федер – NF) челни
                  повърхности.
                </li>
              </ul>
            </div>
          </Row>
          <div className='walls-decision-description-container'>
            <Row className="pt-4 pb-5">
              <Col sm={12} className="mt-5">
                <h2 className="font-weight-bold">Приятно, свежо, здравословно</h2>
                <div className="p-3">
                  <p>
                    От хигиенна и здравословна гледна точка YTONG е изключително
                    подходящ за влагане в жилищни сгради.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="mb-5 justify-content-center">
              <Col xs={5} sm={4} md={3} lg={2}>
                <img src={ytongLogo} alt="" className='w-100 pb-5' />
              </Col>
              <Col xs={11} lg={7} className='offset-lg-1 text-left'>
                <div className="pb-4 d-flex align-items-start">
                  <span className="pr-3 black-check-icon" role="img" aria-label="check">
                    <CheckIcon color="primary" />
                  </span>
                  <span className="h5 font-weight-bold">
                    YTONG регулира по естествен път влажността в помещенията – стените „дишат“ и имат отлична паропропускливост.
                  </span>
                </div>
                <div className="pb-4 d-flex align-items-start">
                  <span className="pr-3 black-check-icon" role="img" aria-label="check">
                    <CheckIcon color="primary" />
                  </span>
                  <span className="h5 font-weight-bold">
                    YTONG има ясно изразена алкална реакция, която възпрепятства развитието на микроорганизми.
                  </span>
                </div>
                <div className="pb-4 d-flex align-items-start">
                  <span className="pr-3 black-check-icon" role="img" aria-label="check">
                    <CheckIcon color="primary" />
                  </span>
                  <span className="h5 font-weight-bold">
                    YTONG не отделя летливи или други токсични вещества, дори при температури над 1000 С°.
                  </span>
                </div>
                <div className="pb-4 d-flex align-items-start">
                  <span className="pr-3 black-check-icon" role="img" aria-label="check">
                    <CheckIcon color="primary" />
                  </span>
                  <span className="h5 font-weight-bold">
                    YTONG не съдържа вещества, които биха предизвикали алергични реакции!
                  </span>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="walls-decision-description-wrapper3">
            <div className="walls-decision-description-container">
              <h3 className="mt-3 font-weight-bold">Уникално съчетание от полезни качества</h3>
              <Col lg={12}>
                <img
                  className="walls-description-image"
                  alt="walls description"
                  src={wallsDescription2}
                />
              </Col>
            </div>
          </Row>
          <Row>
            <Col className="">
              <Card
                onClick={() => getProductsDeclaration({ type: 'ytong' })}
                btnText="свали"
                btnClass="building-guide-card-btn"
                cardClass="walls-decision-card"
                imageClass="building-guide-card-img"
                cardBodyClass="building-guide-card-body"
                title="Екологична декларация"
                desc="Environmental Product Declaration"
                image={ecoDeclaration}
              />
            </Col>
          </Row>

          <div className="walls-decision-container">
            <div className="py-3 walls-decision-product">
              {
                staticWallsData &&
                staticWallsData.map((p, i) => {
                  return (
                    <div
                      key={i}
                      name={`ytong${i}`}
                    >
                      <hr />
                      <Row className="pt-5 align-items-center">
                        <Col sm={8} className="text-left">
                          <h6 className='font-weight-bold'>
                            {p.node.small_title}
                          </h6>
                          <h2 className='font-weight-bold'>
                            {p.node.big_title}
                          </h2>
                          <Link
                            className="blue-link cursor-pointer mt-4"
                            to='top'
                            smooth={true}
                          >
                            начало
                          </Link>
                        </Col>
                        <Col sm={4} className="text-right">
                          <Button
                            className="w-75 p-3 walls-btn text-uppercase shadow"
                            onClick={() => {
                              setId(p.node.id)
                              setModalOpen(true)
                            }}
                          >
                            Изпрати запитване
                          </Button>
                        </Col>
                      </Row>
                      <Row className="my-5 align-items-center">
                        <Col sm={12} md={4} lg={5}>
                          <div dangerouslySetInnerHTML={{ __html: p.node.description }} />
                        </Col>
                        <Col sm={12} md={8} lg={7}>
                          <GatsbyImage
                            image={getImage(p.node.main_image.localFile && p.node.main_image.localFile.childImageSharp)}
                            alt=""
                            objectFit="cover"
                            layout="fixed"
                          />
                          {/* <img
                            alt=''
                            className='w-100'
                            src={`${process.env.GATSBY_XELLA_BACKEND}${p.main_image}`}
                          /> */}
                        </Col>
                      </Row>
                      <Row className="my-5 justify-content-center">
                        {
                          p.node.features.map((f, index) => (
                            <Col className="walls-yellow-tabs" key={index} sm={7} md={4} lg={3}>
                              <GatsbyImage
                                image={getImage(f.localFile && f.localFile.childImageSharp)}
                                alt=""
                                objectFit="cover"
                                layout="fixed"
                              />
                              {/* <img
                                alt=''
                                className='w-100'
                                src={`${process.env.GATSBY_XELLA_BACKEND}${f}`}
                              /> */}
                            </Col>
                          ))
                        }
                      </Row>
                      <Row>
                        <Col sm={12} className="py-5">
                          <GatsbyImage
                            image={getImage(p.node.final_image && p.node.final_image.localFile && p.node.final_image.localFile.childImageSharp)}
                            alt=""
                            objectFit="cover"
                            layout="fixed"
                          />
                          {/* <img
                            alt=''
                            className='w-100'
                            src={`${process.env.GATSBY_XELLA_BACKEND}${p.final_image}`}
                          /> */}
                        </Col>
                      </Row>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <RecentNews
            staticLastProject={staticLastProject}
            staticLastNew={staticLastNew}
            staticLastArticle={staticLastArticle}
          />
          <BuildingGuideModal
            title="Попълнете формата и изпратете запитване"
            buttonText="Изпрати запитване"
            isOpen={isModalOpen}
            productRequest
            id={id}
            onClick={() => setModalOpen(!isModalOpen)}
          />
          <ConfirmationModal
            title={`Вашето запитване беше изпратено успешно.`}
            isOpen={successModal}
            onClick={() => setModal()}
          />
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // products: state.products.data,
    successModal: state.home.successModal
  };
};

const mapDispatchToProps = {
  getProducts,
  getProductsDeclaration,
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(WallsYTONG);

export const query = graphql`
  {
    wallsYtong: allStrapiWallsytong {
      edges {
        node {
          id: strapiId
          big_title
          small_title
          description
          main_image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
          final_image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
          features {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    },
    lastArticles: allStrapiArticleslast {
      edges {
        node {
          id: strapiId
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    },
    lastNews: allStrapiNewslast {
      edges {
        node {
      id: strapiId
      title
      description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
    }
   }
  },
    lastProjects: allStrapiProjectslast {
      edges {
        node {
          id: strapiId
          title
          author_name
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: AUTO, placeholder: BLURRED)
              }
            }
          }
        }
      }
    },
  }
`